.header {
  height: rem(56px);
  margin-bottom: rem(120px);
  background-color: var(--mantine-color-body);
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.inner {
  height: rem(56px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  padding: rem(8px) rem(12px);
  margin-left: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.firstLink {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}

.linkLabel {
  margin-right: rem(5px);
}